import React from "react";
import "./App.css";
import Profile from "./components/Profile";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div className="App">
      <main className="main-info">
        <Profile />
      </main>
      <footer className="footer">
        <p>
          &copy; {new Date().getFullYear()}{" "}
          <a href="https://catacolabs.com" target="_blank" rel="noreferrer">
            CatacoLabs
          </a>
        </p>
      </footer>
      <Analytics />
    </div>
  );
}

export default App;
