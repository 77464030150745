import React from "react";
import profilePic from "../img/avatar.jpg";
import Pdf from "../docs/resume.pdf";
import styles from "./Profile.module.css";

// Updated imports for MUI v5
import { IconButton, Tooltip } from "@mui/material";
import { 
  GitHub as GithubIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
  Assignment as AssignmentIcon,
  Payment as PaymentIcon
} from "@mui/icons-material";

const socialLinks = [
  { 
    name: "github", 
    icon: GithubIcon, 
    url: "https://github.com/lucataco/",
    ariaLabel: "GitHub profile"
  },
  { 
    name: "twitter", 
    icon: TwitterIcon, 
    url: "https://twitter.com/lucataco93",
    ariaLabel: "Twitter profile"
  },
  { 
    name: "linkedIn", 
    icon: LinkedInIcon, 
    url: "https://www.linkedin.com/in/lucataco",
    ariaLabel: "LinkedIn profile"
  },
  { 
    name: "paypal", 
    icon: PaymentIcon, 
    url: "https://paypal.me/lucataco",
    ariaLabel: "PayPal"
  },
  { 
    name: "resume", 
    icon: AssignmentIcon, 
    url: Pdf,
    ariaLabel: "Resume"
  }
];

const Profile = () => {
  const handleLinkClick = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <section className={styles.profileContainer}>
      <img
        src={profilePic}
        alt="Luis D Catacora profile"
        className={styles.profileImage}
      />
      <h1 className={styles.name}>Luis D Catacora</h1>
      <p className={styles.bio}>
        Computer Engineer who likes to tinker and hack things
      </p>

      <div className={styles.socialMediaButtons}>
        {socialLinks.map((link) => (
          <Tooltip key={link.name} title={link.name}>
            <IconButton 
              aria-label={link.ariaLabel}
              onClick={() => handleLinkClick(link.url)}
              size="large"
            >
              <link.icon />
            </IconButton>
          </Tooltip>
        ))}
        <Tooltip title="me@lucataco.com">
          <a href="mailto:me@lucataco.com">
            <IconButton aria-label="Email" size="large">
              <EmailIcon />
            </IconButton>
          </a>
        </Tooltip>
      </div>
    </section>
  );
};

export default Profile;
